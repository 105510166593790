@media all and (min-width: 400px) {
    .CreateUser {
      padding: 50px 0;
    }
  
    .CreateUser form {
      margin: 0 auto;
      max-width: 320px;
    }
  }
  
  .CreateUser form span.help-block {
    font-size: 6px;
    padding-bottom: 4px;
    color: #999;

  }
  