@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }
  
  .__react_component_tooltip.type-light.border.place-bottom:before{
    border-bottom:8px solid grey;
    /* background-color:rgba(255,0,255,0); */
}

  .tooltip-custom {
    font-weight: 800;
    border-radius: 2rem;
    border: 1px solid grey;
    background-color: solid white;
    color: black ;
    opacity:1;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
  .RectangleDark {
    width: 64px;
    height: 64px;
    background-color: #000000;
  }
  .Row {
    width: 35px;
    height: 35px;
    background-color: #e1e3e7;
  }
  .RectangleShaded {
    width: 64px;
    height: 64px;
    background-color: #888888;
  }



  .Title {
    width: 136px;
    height: 24px;
    font-family: Gotham;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .LeftMenu {
    height: 3000px;
    background-color: #007dbc; /*#d8d8d8*/
    float:left; 
    padding-left:10px; 
    width:64px; 
    padding-right:10px;
    overflow:hidden;
    position:absolute; 
    z-index:10


  }

  .title_add_section {
    /* width: 76px;
    height: 15px; */
    /* font-family: AvenirNextLTPro; */
    background-color: #f7f7f7;
    margin-top: 20px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }

  .plan_title{
    padding-left:10px;
    height: 59px;
    font-family: "Ebrima";
    font-size: 24px;
    font-weight: 100;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  .subtitle_page {
    width: 149px;
    height: 24px;
    margin-top: 20px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }

  .add_section_title {
    /* width: 132px; */
    height: 14px;
    /* //font-family: AvenirNextLTPro; */
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }

  .title_page {
    /* width: 387px;
    height: 24px; */
    font-family: Ebrima;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #007dbc; /*#00956e */
  }

  .back_link {
    width: 40px;
    height: 18px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1782c5;
  }

  .Product_Header {
    /* width: 91px;
    height: 24px; */
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }

  .Product_Sub_Header {
    /* width: 91px;
    height: 24px; */
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }

  .Account {
    width: 500px;
    height: 16px;
    padding-top:10px; 
    font-family: Arial, Helvetica, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }

  .MainOuterDiv {
    float:left; 
    min-width:100px; 
    border-width:1px;
    border-style:solid; 
    padding-left:15px; 
    width:90%; 
    padding-right:20px;
    border-radius: 15px; 
    box-shadow: -2px 0 8px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff; 
    position: absolute; 
    z-index: 2
  }
  
  .MainInnerWrapperDiv {
    float: right; 
    min-width:80px;  
    padding-left:45px; 
    width:99%; 
    border-width:1px;
    background-color: #ffffff  ; 
    position: relative; 
    z-index: 0
  }

  .MainCornerDiv {
    float:left; 
    padding-left: 0px; 
    width: 5%;
    border-width: 1px;
    border-color: #007dbc;  /*green  */ 
    border-style: solid; 
    overflow: hidden;
    position: relative; 
    z-index: 1
  }
  .SelectedMenuImgBG {
    width: 45px;
    height: 45px;
    border-radius: 24px;
    background-color: #63bdeb; /* #005745 */
  }

  .SelectedMenuImgBG_white {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: #ffffff;
  }

    .react-datepicker-wrapper 
    .react-datepicker__input-container,
    .react-datepicker__input-container input {
    display: block;
    width: "20%"
}

.InputLabel {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.InputLabelM {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box; 
}
.column,
.columns {
  width: 100%;
  float: left;
  box-sizing: border-box; 
}

ol.progtrckr {
  margin: 0;
  padding-bottom: 2.2rem;
  list-style-type: none;
}
ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  padding: 0 0.7rem;
  cursor: pointer;
}
ol.progtrckr li span {
  padding: 0 1.5rem;
}
@media (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }
}
.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}
@media (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }
}

ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
}
ol.progtrckr li.progtrckr-doing {
  color: black;
  border-bottom: 4px solid #33C3F0;
}
ol.progtrckr li.progtrckr-done {
  color: black;
  border-bottom: 4px solid #33C3F0;
}
ol.progtrckr li:after {
  content: "\00a0\00a0";
}
ol.progtrckr li:before {
  position: relative;
  bottom: -3.7rem;
  float: left;
  left: 50%;
}
ol.progtrckr li.progtrckr-todo:before {
  content: "\039F";
  color: silver;
  background-color: white;
  width: 1.2em;
  line-height: 1.4em;
}
ol.progtrckr li.progtrckr-todo:hover:before {
  color: #0FA0CE;
}

ol.progtrckr li.progtrckr-doing:before {
  content: "\2022";
  color: white;
 /* background-color: #33C3F0;*/
 background-color: '#33C3F0';
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}
ol.progtrckr li.progtrckr-doing:hover:before {
  color: #0FA0CE;
}

ol.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: white;
  background-color: #33C3F0;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}
ol.progtrckr li.progtrckr-done:hover:before {
  color: #0FA0CE;
}

legend {
  border-bottom: 0px !important;
  }
  fieldset {
    display: block;
    margin-inline-start: 2px;
    margin-inline-end: 1px;
    padding-block-start: 0.01em;
    padding-inline-start: 0.75em;
    padding-inline-end: 0.95em;
    padding-block-end: 0.625em;
    min-inline-size: min-content;
    border-width: 1px;
    border-style: solid;
    border-color: gray;
    border-image: initial;
    
}
legend {
  display: block;
  padding-inline-start: 2px;
  padding-inline-end: 0px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
}

  .legend1
  {
    margin-bottom:-8px;
    margin-left:16px;
    background-color: white;
    
  }
  .input-form{
    width: 145px;
    height: 32px;
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    align-content: left;
    
}

.input-legend{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.4px;
    color: #000000;
    align-content: left;
    padding-left: 15px;
}

.form-control {
  width: 80% !important;
}

/* .wiz-circle-selected {
  float: left;
  width: 200px;
  height:36px;
  padding-left: 10px;
  border-radius: 18px;
  background-color: #005745;
  text-align:left;
} */

.wiz-circle-selected {
  float: left;
  /* //width: "200px"; */
  /* height:40px; */
  margin-top: 3px;
  padding-top: 4px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 18px;
  background-color: #005745;
  /* text-align:left; */
}

.wiz-circle {
  float: left;
  width: 36px;
  height:36px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 18px;
  background-color: #000000;
  text-align:center;
  opacity: 0.3;
}
.wiz-text-selected {
  /* //width: 92px; */
  /* height: 32px; */
  margin-right: 15px;
  margin-left: 10px;  
  font-family: Ebrima;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff;
}

.wiz-text {
  font-family:  Ebrima;
  font-size: 17px;
  margin-top:6px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.wiz-text-label {
  font-family: "Gotham";
  font-size: 16px;
  margin-top:8px;
  font-weight: bold;
  font-stretch: normal;
  color: #000000;
}

.wiz-text-label-notselected {
  /* width: 90px;
  height: 16px;*/
  font-family: Ebrima;
  /* //padding-top: 10px; */
  display: 'flex';
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0f9af0;
}

.submenu_item {
  /* width: 24px; */
  padding-left:5px; 
  height: 16px;
  /* font-family: AvenirNextLTPro; */
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  line-height: 1;
  color:  #0f9af0;
}

.ActiveDiv {
  border-style: solid;
  border-color:  teal;
  border-width: 2px;
  border-radius: 4px;
  margin-bottom:20px;
  padding:0px;
}
.InactiveDiv {
  border-style: solid;
  border-color: gray;
  border-width: 1px;
  border-radius: 4px;
  margin-bottom:20px;
  padding:0px;
}

.ql-editor{
  min-height:100px;
}

.campaign-schedule-table {
  width: 75%;
  /* margin-left: 25px; */
  margin-top: 10px;
}

.campaign-review-table {
  width: 75%;
  margin-left: 25px;
  margin-top: 10px;
}

.campaing-review-page svg{
  margin-bottom: -5px;
}

.campaing-review-page a {
  font-size: small;
}

.campaing-review-page a svg {
  font-size: small;
  margin-bottom: -1px;
}


}

